.loginPage {
  background-image: url(../../img/login-bg.jpeg);
  @apply flex
    items-center justify-center
    min-h-screen 
    bg-cover bg-center bg-scroll;
}
.logo {
  position: absolute;
  top: 10px;
  right: 10px;

  width: 100px;
  border-radius: 50%;
}
.formSection {
  @apply mb-6
    bg-indigo-600
    py-10 px-10
    rounded-md;
}
.labels {
  @apply mb-2 
  font-bold text-lg 
  text-white;
}
.inputs {
  @apply flex flex-col 
  mb-4;
}
.textInput {
  @apply rounded-md
    py-2 px-3 
    text-gray-500
    focus:ring-2
    focus:ring-indigo-600;
}
.btn {
  @apply bg-white 
  text-indigo-600
        py-1.5 px-3
        text-sm font-semibold
        transition
        hover:bg-gray-200
        rounded;
}
