button {
  padding: 8px 16px;
}
.todocard {
  position: relative;
  @apply max-w-sm 
    rounded 
    overflow-scroll
    text-center
    shadow-lg 
    h-96 py-2;
}
.todocard:hover .overlay {
  opacity: 1;
}
.todocard input {
  color: #000;
  @apply border 
  rounded 
  mx-2 my-0.5 
  p-1;
}
.todocard button {
  background-color: #ffec4f;
  font-weight: 700;
  color: #000;
  @apply rounded 
  p-1 
  hover:bg-indigo-500 mx-0.5;
}
.todocard h2 {
  color: #000;
  @apply font-bold 
  text-xl;
}
.iconBtn {
  @apply border 
  border-indigo-600 
  rounded 
  ml-auto 
  hover:bg-indigo-600 hover:text-white mx-3;
}
.todotext {
  @apply mr-auto 
  px-5 
  font-bold 
  text-lg;
}
.todos {
  @apply text-indigo-600 
  m-1;
}
.completed {
  text-decoration: line-through;
}
.overlay {
  background-color: #ffec4f;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: none;
  transition: 0.5s ease;
  @apply flex
  items-center 
  justify-center;
}
.overlay button {
  color: #000;
  @apply bg-white 
  px-2 
  hover:bg-gray-200 
  flex 
  items-center 
  m-1;
}
.overlayIcon {
  @apply m-1;
}
