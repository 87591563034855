.dashboardContainer {
  @apply grid 
  grid-cols-12 
  gap-4;
}
.sidebar {
  background-color: #ffec4f;
  @apply lg:col-span-3 
  col-span-12 
  py-5 
   font-bold 
   lg:min-h-screen lg:h-full;
}
.categories {
  @apply w-full 
  text-center;
}
.categories select {
  @apply text-indigo-600 
  w-1/2;
}
.todo {
  @apply lg:col-span-9 
  col-span-12 
  py-5 grid grid-cols-1 gap-4 sm:grid-cols-3;
}
.profileIconContainer {
  width: 200px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 10px;
}

.user {
  @apply px-4;
}
